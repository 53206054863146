import React from 'react';
/*StyleSheets*/
import "../Styles/Style.css";
import "../Styles/bootstrap.min.css";
/*-----------*/

function MainPage() {
    return (
        <>
            <div className="container-fluid">
                <div className="row carousel-caption" id="carousel2">
                    <div className="d-flex justify-content-between animate__animated animate__fadeInDown  mt-3 pt-3 mb-3 pb-3">
                        <div className="col-md-3">
                            <img className="img-fluid" src="./images/logo_camara_turismo_salta_1.png" id="imagen1" alt='logo Camara de Turismo de Salta' />
                        </div>
                        <div className="col-md-3 offset-md-5">
                            <img className="img-fluid" src="./images/marca_salta_1.png" id="imagen" alt='logo Marca Salta' />
                        </div>
                    </div>
                    {/* 
                    <div className="container animate__animated animate__fadeInDown mt-4 pt-4 mb-4 pb-4">
                        <div className="row">
                            <div className="col">
                                <div className="d-flex justify-content-center">
                                    <a href="https://forms.gle/radJXeaiisahgUyp6">
                                        <button className="btn btn-success btn-lg">
                                            <h2 className='text-white'>Si sos prestador de <br />servicios turisticos</h2><h1 className='text-white'><b>→ SUMATE! ←</b></h1>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    */}
                    <div className="container-fluid mt-4 pt-4 mb-4 pb-4 animate__animated animate__fadeInDown">
                        <img className="img-fluid" src="./images/logo_outlet.png" alt="Logo Outlet" />
                    </div>
                    <div className="container-fluid animate__animated animate__fadeInDown mt-5 pt-5 mb-5 pb-5" id="animation">
                        <div className="d-flex justify-content-evenly">
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MainPage;