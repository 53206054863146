import React from 'react';
/*StyleSheets*/
import "../Styles/Style.css";
import "../Styles/bootstrap.min.css";
/*-----------*/

function Footer() {
    return (
        <>
            <div className='container-fluid mt-3 pt-3'>
                <div className='row'>
                    <img className="img-fluid" src="./images/conexion_2.png" id='splitter' alt="" />
                </div>
            </div>
            <div className="container-fluid bg-dark text-light " style={{ paddingTop: '40px' }}>

                <div className="container-fluid" id="footer-logos">
                    <div className="row">

                        <div className="col-lg-10 col-md-12 col-sm-12" style={{ padding: '0px' }}>
                            <div className="d-flex justify-content-start">
                                <img className='img-fluid' src="./images/logo_camara_turismo_salta_2.png" alt="Logo Camara De Turismo De Salta" />
                            </div>
                        </div>


                        <div className="col-lg-2 col-md-12 col-sm-12 pt-5 mb-5">
                            <a href="https://visitsalta.ar/">
                                <img className='img-fluid logo-salta' src="./images/marca_salta_2.png" alt="Logo Salta" />
                            </a>
                        </div>

                    </div>
                </div>

                <div className="container-fluid text-center text-white" id="end">
                    <div className="row justify-content-end">
                        <div className="col">
                            <div className="d-flex align-items-end" style={{ height: '75px' }}>
                                <div className="col">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;