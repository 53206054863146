import React, { useEffect, useState } from 'react';
import "../Styles/Style.css";
import "../Styles/bootstrap.min.css";

function BackToTop() {
    const [reachedBottom, setReachedBottom] = useState(false);

    useEffect(() => {
        let mybutton = document.getElementById("btn-back-to-top");

        function scrollFunction() {
            if (
                document.body.scrollTop > 20 ||
                document.documentElement.scrollTop > 20
            ) {
                mybutton.style.display = "block";
                mybutton.classList.add("animate__animated", "animate__fadeIn");
            } else {
                mybutton.style.display = "none";
                mybutton.classList.remove("animate__animated", "animate__fadeOut");
            }
            if (
                window.innerHeight + window.scrollY >= document.body.offsetHeight
            ) {
                setReachedBottom(true);
            } else {
                setReachedBottom(false);
            }
        }

        function backToTop() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }

        window.addEventListener("scroll", scrollFunction);

        mybutton.addEventListener("click", backToTop);
        return () => {
            window.removeEventListener("scroll", scrollFunction);
            mybutton.removeEventListener("click", backToTop);
        };
    }, []);

    return (
        <>
            <button
                type="button"
                className={`btn btn-floating btn-lg ${reachedBottom ? 'btn-mystery' : 'btn-primary'}`}
                id="btn-back-to-top"
            >
                <i className="fas fa-arrow-up"></i>
            </button>
        </>
    );
}

export default BackToTop;
