import MainPage from "./Components/Main";
import Footer from "./Components/Footer";
import BackToTop from "./Components/BackToTop";
import NewsSection from "./Components/NewsSection";

function App() {
  return (
    <div className="App">
      <MainPage></MainPage>
      <NewsSection />
      <Footer />
      <BackToTop />
    </div>
  );
}

export default App;
