function NewsSection() {

    return (
        <div className='container-fluid mb-5 pb-5'>
            <div className='row'>
                <img className="img-fluid" src="./images/conexion_1.png" id='splitter' alt="" />
            </div>
            <div className='row' id="main-background" >
                <div className="container" id='outlet-container'>
                    <div className="d-flex justify-content-center">

                        <video className="video-prom-salta" controls>
                            <source src="/SALTA-TE-CONQUISTA-Y-TE-ENAMORA.mp4" type="video/mp4" />
                        </video>

                    </div>
                </div>
            </div>
            <div className='row mb-5 pb-5'>
                <img className="img-fluid" src="./images/conexion_1.png" id='splitter1' alt="" />
            </div>
        </div>
    )
}

export default NewsSection;
